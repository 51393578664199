<template>
  <div class="about-us-page eat-page">
    <section class="section-food">
      <div class="container">
        <div class="section-food-header">
          <div class="row justify-content-between">
            <div class="col-md-6 col-sm-12">
              <div class="text-lg">
                {{ $t("Joylashgan hududingizdagi taomlar") }}
              </div>
              <div class="text-sm">
                {{ $t("text for subfilter, some text") }}
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="section-food-selects">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="my-select">
                      <div class="custom-select">
                        <select
                          v-model="regionId"
                          @change="$store.dispatch('getDistricts', regionId)"
                        >
                          <option value="" disabled>
                             {{ $t("Regionni tanlang") }}
                            
                          </option>
                          <option
                            v-for="region in regions"
                            :key="region.id"
                            :value="region.id"
                          >
                            {{ region.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="my-select my-select-last">
                      <div class="custom-select">
                        <select
                          v-model="districtId"
                          @change="
                            $store.dispatch('filterOrgDis', {
                              regionId: regionId,
                              districtId: districtId,
                            })
                          "
                        >
                          <option value="" selected disabled>
                           {{ $t("Shaharni tanlang") }}
                          </option>
                          <option
                            v-for="district in districts"
                            :key="district.id"
                            :value="district.id"
                          >
                            {{ district.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="food-menu">
            <ul class="bottom-border" v-if="regions.length > 0">
              <li>
                <a href="#!" v-if="regionId !== ''">{{
                  regions.find((el) => el.id == regionId).name
                }}</a>
              </li>
              <li>
                <a href="#!" v-if="districtId !== ''">{{
                  districts.find((el) => el.id == districtId).name
                }}</a>
              </li>
            </ul>
            <div class="row margin-top">
              <div class="col-lg-6 col-xl-6 col-md-10 col-sm-12">
                <div class="my-eat-card">
                  <div class="mye-img" v-if="'image' in dish">
                    <img
                      v-if="dish.image !== null"
                      :src="dish.image.thumbnails.medium"
                      alt=""
                    />
                  </div>
                  <div class="mye-img" v-else>
                    <img
                      src="../../../assets/images/default-img.svg"
                      alt="img not found :("
                    />
                  </div>

                  <div class="mye-links">
                    <a href="#!" v-if="organization.address !== undefined">
                      <img
                        src="../../../assets/images/icons/loc.svg"
                        alt=""
                      />{{ organization.address.slice(0, 35) + " ..." }}</a
                    >
                    <a href="#!" v-if="false">
                      <img
                        src="../../../assets/images/icons/bag.svg"
                        alt=""
                      />{{ $t("Yetkazib berish xizmati mavjud") }}</a
                    >
                    <a :href="'tel:' + organization.contact" class="phone-link">
                      <img
                        src="../../../assets/images/icons/pho.svg"
                        alt=""
                      />{{ organization.contact }}</a
                    >
                  </div>
                  <div class="title">{{ dish.title }}</div>

                  <div class="sub">
                    <div v-html="dish.description"></div>
                  </div>
                  <div class="title">
                    {{ $t("Taom narxi :") }} {{ dish.price }} $
                  </div>
                  <share-2></share-2>
                </div>
              </div>
              <div class="col-lg-5 col-xl-4 col-md-10 col-sm-12">
                <form @submit.prevent="booking()" class="zakaz-form">
                  <div class="info">
                    {{ $t("buyurtma-berish-tepasi") }}
                  </div>
                  <div class="functions">
                    <label for="">{{ $t("Odam soni") }}</label>
                    <div class="controllers">
                      <button
                        type="button"
                        class="minus"
                        @click="minus('person')"
                      >
                        -
                      </button>
                      <input
                        :class="{ 'not-active': personNotActive }"
                        type="text"
                        v-model="person"
                        disabled="disabled"
                      />
                      <button
                        type="button"
                        class="plus"
                        @click="plus('person')"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div class="functions">
                    <label for="">{{ $t("Portsiya") }}</label>
                    <div class="controllers">
                      <button
                        type="button"
                        class="minus"
                        @click="minus('count')"
                      >
                        -
                      </button>
                      <input
                        :class="{ 'not-active': countNotActive }"
                        type="text"
                        v-model="count"
                        disabled="disabled"
                      />
                      <button type="button" class="plus" @click="plus('count')">
                        +
                      </button>
                    </div>
                  </div>
                  <div class="calendar">
                    <div class="data">
                      <img src="../../../assets/images/icons/data.svg" alt="" />
                      <datetime type="datetime" v-model="datetime"></datetime>
                    </div>
                  </div>
                  <div
                    class="info-2"
                    @click="checkDropOpen = !checkDropOpen"
                    :class="{ activated: checkDropOpen }"
                  >
                    <div class="my-drop-down">
                      {{ $t("Use one of the descriptions") }}
                      <img
                        src="../../../assets/images/icons/arrow-down.svg"
                        alt=""
                      />
                    </div>
                    <div class="drop-down-menu">
                      <div class="dm-content">
                        <label class="chekcbox-con"
                          >{{ $t("eat-description1") }}
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                        <label class="chekcbox-con"
                          >{{ $t("eat-description2") }}
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                        <label class="chekcbox-con"
                          >{{ $t("eat-description3") }}
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="chekcboxs">
                    <label class="chekcbox-con"
                      >{{ $t("tasdiqlash") }}
                      <input required type="checkbox" checked="checked" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <input
                    v-if="!bookingLoading"
                    type="submit"
                    :value="$t('yuborish')"
                    class="more-button"
                  />
                  <loader v-else></loader>
                </form>
              </div>
            </div>
            <section class="rek-menu">
              <div class="row" v-if="filteredOrgs.length > 0">
                <restoran-card
                  v-for="item in regionId == ''
                    ? filteredOrgs.slice(0, 6)
                    : filteredOrgs"
                  :key="item.id"
                  :adress="item.address"
                  :legal_name="item.title"
                  :orgId="item.id"
                  :contact="item.contact"
                  :orgLogo="item.image"
                >
                </restoran-card>
              </div>
              <div class="more-button">
                <router-link
                  @click.native="$store.dispatch('allOrganization')"
                  :to="'/' + $i18n.locale + '/organization'"
                >
                  {{ $t("Barchasi") }}
                </router-link>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <section class="au-first">
      <mini-cards></mini-cards>
    </section>
  </div>
</template>

<script>
import RestoranCard from "../../elements/RestoranCard.vue";
import MiniCards from "../../elements/MiniCards";
import { Datetime } from "vue-datetime";
import { mapState } from "vuex";
import axios from "axios";
import Share2 from "../../elements/Share2.vue";
export default {
  components: { RestoranCard, MiniCards, Datetime, Share2 },
  data() {
    return {
      checkDropOpen: false,
      person: 1,
      count: 1,
      datetime: "",
      countNotActive: true,
      personNotActive: true,
      bookingLoading: false,
    };
  },
  watch: {
    person() {
      this.personNotActive = false;
    },
    count() {
      this.countNotActive = false;
    },
  },
  methods: {
    plus(val) {
      if (this[val] < 29) this[val]++;
    },
    minus(val) {
      if (this[val] > 1) this[val]--;
    },
    booking() {
      if (this.authorized) {
        this.bookingLoading = true;
        axios
          .post(
            "booking/dish",
            {
              product_id: this.$route.params.dishId,
              date: new Date(this.datetime).getTime() / 1000,
              table: "2",
              people: this.person,
              portion: this.count,
            },
            {
              headers: {
                Authorization: `Bearer ${this.user.token}`,
              },
            }
          )
          .then((res) => {
            this.bookingLoading = false;
            console.log(res.data.data);
            this.$router.push({ name : 'userBooking' })
          })
          .catch((error) => {
            this.bookingLoading = false;
            console.log(error.response.data.errors);
            for (const er in error.response.data.errors) {
              this.$toasted.show(error.response.data.errors[er], {
                duration: 3000,
                type: "error",
                icon: "error-icon",
              });
            }
          });
      } else {
        this.$toasted.show("Authorization failed!", {
          duration: 4000,
          type: "error",
          icon: "error-icon",
          action: [
            {
              text: "signUp",
              // router navigation
              onClick: () => {
                this.$router.push({name : 'signIn'});
                this.$store.commit('GO_EAT_SINGLE', true)
              },
            },
          ],
        });
      }
    },
  },
  mounted() {
    let time = document.querySelector(".vdatetime-input");
    time.placeholder = "Vaqtni tanlang";
    this.$store.dispatch("getDish", {
      orgId: this.$route.params.orgId,
      dishId: this.$route.params.dishId,
    });
    this.$store.dispatch("getOrganization", this.$route.params.orgId);
  },
  computed: {
    ...mapState([
      "organizations",
      "organization",
      "dish",
      "authorized",
      "user",
      "regions",
      "districts",
      "filteredOrgs",
    ]),
    regionId: {
      get() {
        return this.$store.state.regionId;
      },
      set(value) {
        this.$store.commit("SET_REGION_ID", value);
      },
    },
    districtId: {
      get() {
        return this.$store.state.districtId;
      },
      set(value) {
        this.$store.commit("SET_DISTRICT_ID", value);
      },
    },
  },
};
</script>

<style scoped>
.about-us-page.eat-page
  .section-food
  .zakaz-form
  .chekcbox-con
  .checkmark:after {
  width: 6px;
  height: 11px;
}
</style>
<style>
.error-icon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
  background-image: url("../../../assets/images/warning.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.success-icon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
  background-image: url("../../../assets/images/check.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.toasted-container .toasted {
  font-family: "Baloo2";
  font-size: 18px;
}
.calendar .vdatetime-calendar__month__day--selected > span > span,
.vdatetime-calendar__month__day--selected:hover > span > span {
  background: #fc772c;
}
.calendar .vdatetime-popup__header {
  background: #fc772c;
}
.calendar .vdatetime-calendar__month__day--selected:hover span span {
  background: #fc772c;
}
.vdatetime-input::placeholder {
  color: red;
}
.about-us-page.eat-page .section-food .zakaz-form .calendar {
  padding-top: 0;
  padding-bottom: 0;
  cursor: default;
}
.vdatetime-input {
  padding: 30px 25px 30px 0;
  cursor: pointer;
}
</style>